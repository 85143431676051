import { forEach, map } from 'lodash';
import ParticleImage from 'components/organisms/particleCanvas/ParticleImage';

let isStart = false;
let itemList: Array<ParticleImage> = [];

export default class ParticleImageManager {
  public static push(item: ParticleImage): void {
    itemList.push(item);
  }

  public static isStart(): boolean {
    return isStart;
  }

  public static start(): void {
    forEach(itemList, item => item.start());

    ParticleImageManager.onScroll();

    window.addEventListener('resize', ParticleImageManager.onResize);
    window.addEventListener('scroll', ParticleImageManager.onScroll);
  }

  public static delete(): void {
    isStart = false;

    forEach(itemList, item => item.delete());
    itemList = [];

    window.removeEventListener('resize', ParticleImageManager.onResize);
    window.removeEventListener('scroll', ParticleImageManager.onScroll);
  }

  public static generateAll(): Promise<void[]> {
    const promiseList: Array<Promise<void>> = map(itemList, item => item.generate());
    return Promise.all(promiseList);
  }

  public static loadAll(): Promise<void[]> {
    isStart = true;
    const promiseList: Array<Promise<void>> = map(itemList, item => ParticleImageManager.load(item));
    return Promise.all(promiseList);
  }

  private static onResize(): void {
    forEach(itemList, item => item.onResize());
  }

  private static onScroll(): void {
    forEach(itemList, item => item.onScroll(window.scrollY));
  }

  private static load(item: ParticleImage): Promise<void> {
    return new Promise((resolve, reject) => {
      item
        .load()
        .then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
    });
  }
}
